import React from 'react';

import styled from 'styled-components';

import { ProviderProps } from '../../@types/types';
import { buildBreakpoint } from '../../styles/common';
import { EditNavbar } from './Nav';
import { PublishedChangesAlert } from '../../components/common/Popper';
import { EditSidebar } from './Sidebar';
import { useEditContext } from '../../context/EditContext';

const ContentContainer = styled.main`
    display: flex;
    transition: 0.2s;
    position: relative;
    ${buildBreakpoint('tablet')} {
        margin-bottom: 8rem;
    };
`;

const MainContent = styled.div<{ $sidebarOpen?: boolean; }>`
    min-height: 100vh;
    margin-top: 5rem;
    margin-left: ${(props) => props.$sidebarOpen ? 400 : 0}px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s;
    background-color: white;
    position: relative;
    ${buildBreakpoint('tablet')} {
        margin-left: 0;
    };
`;

const ChildrenWrapper = styled.div`
    width: 100%;
    margin-inline: 2rem;
    max-width: 2000px;
`;

export const EditLayout: React.FC<ProviderProps> = ({ children }) => {
    const { sidebarOpen } = useEditContext();

    return (
        <ContentContainer>
            <PublishedChangesAlert />
            <EditSidebar />
            <MainContent $sidebarOpen={sidebarOpen}>
                <EditNavbar />
                <ChildrenWrapper>
                    {children}
                </ChildrenWrapper>
            </MainContent>
        </ContentContainer>
    );
};
