import {
    MOBILE_BREAKPOINT,
    TABLET_BREAKPOINT,
    LAPTOP_BREAKPOINT,
    PRIMARY_RGB,
    THEME_RED_RGB,
    THEME_GREEN_RGB,
    REDUCED_WIDTH_BREAKPOINT,
    CONTENT_MAX_WIDTH,
    GRAY_RGB
} from "./variables";

export type MainTheme = typeof lightTheme;

const colors = {
    primary: '#6366F1',
    primaryLight: 'rgba(100, 102, 244, 0.6)',
    primarySuperLight: 'rgba(0, 177, 255, 0.2)',
    primaryFaded: `rgba(${PRIMARY_RGB}, 0.1)`,
    primaryFadedHover: `rgba(${PRIMARY_RGB}, 0.05)`,
    primaryDark: '#1C2536',
    gray: `rgb(${GRAY_RGB})`,
    border: `rgba(${GRAY_RGB}, 0.3)`,
    surface: '#fff',
    text: '#1a1a1a',
    textSecondary: 'rgba(108, 115, 127)',
    secondary: '#32EEB6',
    secondaryLight: '#A4F78B',
    lightGray: 'rgba(100,100,100,0.2)',
    textPrimary: 'rgb(17, 25, 39)',
    red: `rgba(${THEME_RED_RGB}, 1)`,
    green: `rgba(${THEME_GREEN_RGB}, 1)`,
    navItem: '#9DA4AE',
    navItemActive: 'rgba(100, 102, 244, 0.2)'
};

const breakpoints = {
    mobile: `${MOBILE_BREAKPOINT}px`,
    tablet: `${TABLET_BREAKPOINT}px`,
    laptop: `${LAPTOP_BREAKPOINT}px`
}

const widths = {
    contentMaxWidth: `${CONTENT_MAX_WIDTH}px`,
    contentReducedWidth: `${REDUCED_WIDTH_BREAKPOINT}px`
}

const baseTheme = {
    colors,
    widths,
    breakpoints
}

export const lightTheme = {
    ...baseTheme,
    colors,
    body: 'white',
    bodyFaded: 'rgba(255,255,255,0.7)',
};

export const darkTheme = {
    ...baseTheme,
    colors: {
        ...colors,
        surface: 'rgb(17, 25, 39)',
        text: '#f7f7f7',
        textPrimary: 'rgb(237, 242, 247)',
        textSecondary: 'rgba(160, 174, 192)'
    },
    body: 'rgb(14, 19, 32)',
    bodyFaded: 'rgba(14, 19, 32, 0.7)'
};