import React from 'react';

import styled from 'styled-components';

import { ProviderProps } from '../../@types/types';
import { Sidebar } from '../Sidebar';
import { Navbar } from '../Navbar';
import { buildBreakpoint } from '../../styles/common';
import { SIDEBAR_WIDTH } from '../../styles/variables';

const ContentContainer = styled.main`
    display: flex;

    ${buildBreakpoint('tablet')} {
        margin-bottom: 8rem;
    };
`;

const MainContent = styled.div`
    margin-left: ${SIDEBAR_WIDTH}px;
    min-height: 100vh;
    margin-top: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;

    ${buildBreakpoint('tablet')} {
        margin-left: 0;
    };
`;

const ChildrenWrapper = styled.div`
    width: 100%;
    margin-inline: auto;
    padding-inline: 2rem;
    max-width: ${({ theme }) => theme.widths.contentMaxWidth}
`;

export const DashboardLayout: React.FC<ProviderProps> = ({ children }) => {
    return (
        <ContentContainer>
            <Sidebar />
            <MainContent>
                <Navbar />
                <ChildrenWrapper>
                    {children}
                </ChildrenWrapper>
            </MainContent>
        </ContentContainer>
    );
};
