import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ThemeMode, useThemeContext } from './context/ThemeContext';
import { routes } from './routes';
import { GlobalStyles } from './styles/globalStyles';
import { darkTheme, lightTheme } from './styles/theme';

export const App = () => {
    const allPages = useRoutes(routes);
    const { theme } = useThemeContext();

    return (
        <ThemeProvider theme={theme === ThemeMode.DARK ? darkTheme : lightTheme}>
            <GlobalStyles />
            {allPages}
        </ThemeProvider>
    );
};
