import React from 'react';

import styled, { css } from 'styled-components';

const loaderStyles = css`
    .loader {
        width: calc(100px - 24px);
        height: 50px;
        position: relative;
        animation: flippx 2s infinite linear;
    }
    .loader:before {
        content: "";
        position: absolute;
        inset: 0;
        margin: auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: ${(props) => props.theme.colors.primary};
        transform-origin: -24px 50%;
        animation: spin 1s infinite linear;
    }
    .loader:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50% , -50%);
        background: ${(props) => props.theme.colors.primary};
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }
    
    @keyframes flippx {
        0%, 49% {
        transform: scaleX(1);
        }
        50%, 100% {
        transform: scaleX(-1);
        }
    }
    @keyframes spin {
        100% {
        transform: rotate(360deg);
        }
    }
`;

const LoaderContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10rem;
    z-index: 100000000;
    background-color: white;

    ${loaderStyles};
`;

export const Loader: React.FC = () => {
    return (
        <LoaderContainer>
            <div className='loader' />
        </LoaderContainer>
    );
};
