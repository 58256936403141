import styled from "styled-components";

import { useEditContext } from "../../../context/EditContext";
import { useMemo, useState } from "react";
import { Icon } from "../../../components/Icon";
import { flexBetween } from "../../../styles/utils";
import _ from 'lodash';

const Container = styled.nav<{ $open: boolean; }>`
    position: fixed;
    width: 400px;
    transition: 0.2s;
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 5rem;
    z-index: 1000;
    top: 88px;
    background-color: ${({ theme }) => theme.body};
    left: ${(props) => props.$open ? 0 : -500}px;
`;

const PageItemWrapper = styled.div<{ $active?: boolean; }>`
    padding: 0.8rem 1rem;
    cursor: pointer;
    margin: 1rem 0;
    transition: 0.2s;
    border-radius: 5px;
    background-color: ${(props) => props.$active ? props.theme.colors.navItemActive : 'transparent'};

    &:hover {
        background-color: ${({ theme }) => theme.colors.navItemActive};
    }

    p {
        margin: 0;
    }
`;

const Buffer = styled.div`
    margin: 10rem 0;
`;

const InnerWrapper = styled.div`
    padding: 2rem;
`;

const SectionAccordion = styled.div`
    position: relative;
`;

const AccordionTitleButton = styled.div<{ $expanded: boolean; }>`
    cursor: pointer;
    transition: 0.2s;
    z-index: 10;
    ${flexBetween};
    
    svg {
        transition: 0.2s;
        transform: rotate(${(props) => props.$expanded ? 0 : 180}deg);
    }
`;

const AccordionBody = styled.div<{ $expanded: boolean; $gutters?: boolean; }>`
    transition: 0.3s;
    width: 100%;
    opacity: ${(props) => props.$expanded ? '1' : '0'};
    height: ${(props) => props.$expanded ? 'auto' : '0px'};
    overflow-y: ${(props) => props.$expanded ? 'auto' : 'hidden'};
    overflow-x: hidden;
    padding-inline: ${(props) => props.$gutters ? 1 : 0}rem;
`;

interface AccordionState {
    pagesExpanded: boolean;
}

interface DynamicPageWrapperProps {
    items: any;
    title: string;
    handleClick: (url: string) => void;
}

const urlToName = (url: string) => url.split('-').map((part) => part.charAt(0).toLocaleUpperCase() + part.slice(1, part.length)).join(' ');

const DynamicPageWrapper = ({ items, title, handleClick }: DynamicPageWrapperProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const formattedItems = useMemo(() => {
        return items?.map((item: any) => {
            const pageTitle = urlToName(item?.url?.split('/')[2]);
            return { ...item, pageTitle }
        })
    }, [items]);

    return (
        <>
            <AccordionTitleButton
                $expanded={expanded}
                onClick={() => setExpanded(!expanded)}
            >
                <PageItemWrapper>
                    <p>{title}</p>
                </PageItemWrapper>
                <Icon variant="expand" width={30} height={30} />
            </AccordionTitleButton>
            <AccordionBody $expanded={expanded} $gutters>
                {formattedItems?.map((item, index) => (
                    <PageItemWrapper key={item.url} $active={false} onClick={() => handleClick(item.url)}>
                        <p>{item.pageTitle}</p>
                    </PageItemWrapper>
                ))}
            </AccordionBody>
        </>
    );
}

export const EditSidebar: React.FC = () => {
    const { sidebarOpen, allSiteData, pageIndex, fetchNewPage } = useEditContext();

    const [accordionState, setAccordionState] = useState<AccordionState>({
        pagesExpanded: true
    });

    const formattedPageData = useMemo(() => {
        const staticPages = allSiteData?.pageData?.filter((item) => item.type === 'page').map((item) => {
            const pageName = item?.url === '/' ? 'Home' : urlToName(item?.url?.split('/')[1]);

            return { ...item, pageName };
        });

        const dynamicPages = allSiteData?.pageData?.filter((item) => item.type === 'dynamicPage').map((item) => {
            const pageName = urlToName(item?.url?.split('/')[1]);

            return { ...item, pageName };
        });

        const grouped = _.groupBy(dynamicPages, 'pageName');
    
        return { staticPages, dynamicPages: grouped, allData: allSiteData?.pageData };
    }, [allSiteData]);
    console.log(formattedPageData)
    const handleAccordionClicked = (key: keyof AccordionState) => {
        setAccordionState((prev) => ({
            ...prev,
            [key]: !prev[key]
        }));
    };

    const handleFindDynamicPage = (url: string) => {
        const index = formattedPageData.allData.findIndex((item) => item.url === url);
        fetchNewPage(index);
    }

    return (
        <Container $open={sidebarOpen} id="editSidebar">
            <InnerWrapper>
                <SectionAccordion>
                    <div>
                        <h2>Pages</h2>
                        {/* <Icon variant="expand" width={30} height={30} /> */}
                    </div>
                    <AccordionBody $expanded={accordionState.pagesExpanded}>
                        {formattedPageData?.staticPages?.map((item, index) => (
                            <PageItemWrapper key={item.url} $active={index === pageIndex} onClick={() => fetchNewPage(index)}>
                                <p>{item.pageName}</p>
                            </PageItemWrapper>
                        ))}
                        {Object.entries(formattedPageData?.dynamicPages)?.map(([key, items]) => (
                            <DynamicPageWrapper
                                key={key}
                                title={key}
                                items={items}
                                handleClick={handleFindDynamicPage}
                            />
                        ))}
                    </AccordionBody>
                </SectionAccordion>
            </InnerWrapper>
            <Buffer />
        </Container>
    );
};
