import styled from 'styled-components';
import { AccountMenu } from '../../components/Navbar';
import { useNavContext } from '../../context/NavContext';
import { buildBreakpoint } from '../../styles/common';
import { SIDEBAR_WIDTH } from '../../styles/variables';

const NavbarContainer = styled.header`
    position: fixed;
    top: 0;
    left: 1px;
    z-index: 2;
    height: 50px;
    backdrop-filter: blur(6px);
    margin-left: ${SIDEBAR_WIDTH}px;
    padding: 1rem;
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.bodyFaded};
    border-bottom: 1px solid ${(props) => props.theme.colors.border};

    ${buildBreakpoint('tablet')} {
        margin-left: 0;
        border-left: none;
    };
`;

const NavbarInnerContainer = styled.div`
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const NavTitle = styled.p`
    font-size: 1.2rem;
    font-weight: 500;
`;

export const Navbar: React.FC = () => {
    const { title } = useNavContext();
    return (
        <NavbarContainer>
            <NavbarInnerContainer>
                <NavTitle>{title}</NavTitle>
                <AccountMenu />
            </NavbarInnerContainer>
        </NavbarContainer>
    );
};
