import { css } from "styled-components";

export const flexBetween = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const flexColumnCenter = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
