import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import {
    User,
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
    signOut
} from "firebase/auth";
import {
    getDatabase,
    ref,
    set as setter,
    get as getter,
    onValue
} from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyAeNJYx2eSyvleAe_UnVTfX937NsEXfms4",
    authDomain: "flospace-portal.firebaseapp.com",
    projectId: "flospace-portal",
    storageBucket: "flospace-portal.appspot.com",
    messagingSenderId: "1081563429086",
    appId: "1:1081563429086:web:5d1b3b99646aa216062a4f",
    measurementId: "G-70NMMD00VB"
};

export const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const database = getDatabase(app);
export const storage = getStorage();

export interface UserInterface extends User { };

class Database {
    async get<T>(path: string): Promise<T> {
        return await new Promise(async (resolve, reject) => {
            await getter(ref(database, path))
                .then((snap) => resolve(snap.val() as T))
                .catch((e) => reject(e))
        })
    };

    async set(path: string, values: any) {
        return await new Promise(async (resolve, reject) => {
            await setter(ref(database, path), values)
            .then(() => resolve(''))
            .catch((e) => reject(e))
        })
    }
}

export const db = new Database();

export {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signInWithPopup,
    signOut,
    ref,
    getter,
    onValue
}