import { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useEditContext } from '../../../context/EditContext';

export const PublishedChangesAlert: React.FC = () => {
    const { publishedChanges } = useEditContext();

    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        setOpen(publishedChanges);
    }, [publishedChanges]);

    return (
        <Snackbar open={open} autoHideDuration={2000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert
                severity="success"
                variant="filled"
                sx={{ width: '100%', backgroundColor: '#6366F1' }}
            >
                Changes published successfully!
            </Alert>
        </Snackbar>
    );
}
