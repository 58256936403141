import { useState, createContext, useContext, useEffect } from 'react';
import axios from 'axios';
import { isEqual } from 'lodash';

import { ProviderProps } from '../@types/types';

export const STAGED_CHANGES_KEY = 'staged_changes';
export const STAGED_LAYOUT_KEY = 'staged_layout_changes';
interface IEditableComponent {
    id: string;
    component: string;
    fields: any;
}

interface EditTextFromArrayInput {
    value: string;
    key: string;
    parent: string;
    path: string;
    index: number
}

export interface EditContextInterface {
    isEditing: boolean;
    editingComponent: IEditableComponent;
    saving: boolean;
    stagedChanges: any;
    hasStagedChanges: boolean;
    publishing: boolean;
    publishedChanges: boolean;
    sidebarOpen: boolean;
    siteKey: string;
    allSiteData: any;
    pageIndex: number;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setEditingComponent: React.Dispatch<React.SetStateAction<IEditableComponent>>;
    handleEditTextValue: (value: string, key: string, asLayout?: boolean) => void;
    handleEditDynamicLink: (value: string, key: string) => void;
    handleEditSecondaryLink: (value: string, key: string) => void;
    handleAddImage: (value: string, key: string) => void;
    handleRemoveImage: (value: string, key: string) => void;
    handleEditTextFromArray: (input: EditTextFromArrayInput) => void;
    handleEditImageFromArray: (input: EditTextFromArrayInput) => void;
    setSiteKey: React.Dispatch<React.SetStateAction<string>>;
    setPageIndex: React.Dispatch<React.SetStateAction<number>>;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    handleSaveChanges: () => void;
    handleSaveLayoutChanges: () => void;
    handlePublishChanges: () => void;
    debugLocal: () => void;
    fetchNewPage: (index: number) => void;
}

export const EditContext = createContext(undefined as unknown as EditContextInterface);

export const EditContextProvider: React.FC<ProviderProps> = ({ children }) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [siteKey, setSiteKey] = useState<string>();
    const [pageIndex, setPageIndex] = useState<number>();
    const [saving, setSaving] = useState<boolean>(false);
    const [publishing, setPublishing] = useState<boolean>(false);
    const [publishedChanges, setPublishedChanges] = useState<boolean>(false);
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [allSiteData, setAllSiteData] = useState<any>();

    const [editingComponent, setEditingComponent] = useState<IEditableComponent | null>(null);
    const [initialData, setInitialData] = useState<any | null>(null);
    const [initialLayout, setInitialLayout] = useState<any | null>(null);
    const [stagedChanges, setStagedChanges] = useState<any>();

    const [hasStagedChanges, setHasStagedChanges] = useState<boolean>(false);

    // Listener for when component is being edited
    useEffect(() => {
        setIsEditing(!!editingComponent && Object.values(editingComponent)?.length > 0);
    }, [editingComponent]);

    // Handler for detecting previous updates
    useEffect(() => {
        const previousSaveData = JSON.parse(localStorage.getItem(STAGED_CHANGES_KEY)) ?? null;

        if (previousSaveData) {
            const formattedLocalData = (Object.values(previousSaveData).filter((_, index) => index === pageIndex) as any)[0]?.blocks;
            setStagedChanges(formattedLocalData);
            setHasStagedChanges(true);
        }
    }, [pageIndex]);

    // Fetches production data to compare with local changes
    useEffect(() => {
        if (!siteKey) return;

        fetchInitialData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteKey]);

    const handleEditTextValue = (value: string, key: string, asLayout = false) => {
        const activeEditingField = editingComponent?.fields[key]?.value;

        if (!activeEditingField) return;

        setEditingComponent((prev) => {
            if (!prev.fields[key].value) return prev;

            prev.fields[key].value = value;

            return { ...prev };
        });

        if (asLayout) {
            handleSaveLayoutChanges();
        } else {
            handleSaveChanges();
        }
    };

    const handleEditTextFromArray = ({ value, key, parent, path, index }) => {
        const activeEditingField = editingComponent?.fields[key]?.value[index][parent][path];
        
        if (!activeEditingField) return;

        setEditingComponent((prev) => {
            prev.fields[key].value[index][parent][path] = value;
            return { ...prev }
        });

        handleSaveChanges();
    };

    const handleEditSecondaryLink = (value: string, key: string) => {
        const activeEditingField = editingComponent?.fields?.secondaryLink?.value[key];

        if (!activeEditingField) return;

        setEditingComponent((prev) => {
            if (!prev?.fields?.secondaryLink?.value[key]) return prev;

            prev.fields.secondaryLink.value[key] = value;

            return { ...prev }
        })
    }

    const handleEditDynamicLink = (value: string, key: string) => {
        const activeEditingField = editingComponent.fields.dynamicLink.value[key];

        if (!activeEditingField) return;

        setEditingComponent((prev) => {
            if (!prev.fields.dynamicLink.value[key]) return prev;

            prev.fields.dynamicLink.value[key] = value;

            return { ...prev };
        });
    };

    const handleAddImage = (url: string, key: string) => {
        const activeEditingField = editingComponent?.fields[key]?.value;
        
        if (!activeEditingField) return;

        setEditingComponent((prev) => {
            if (Array.isArray(activeEditingField)) {
                prev.fields[key].value.push(url)
            } else {
                prev.fields[key].value = url
            }

            return { ...prev };
        });

        handleSaveChanges();
    };

    const handleEditImageFromArray = ({ value, key, parent, path, index }) => {
        const activeEditingField = editingComponent?.fields[key]?.value[index][parent][path];

        if (!activeEditingField) return;

        setEditingComponent((prev) => {
            prev.fields[key].value[index][parent][path] = value;
            return { ...prev }
        });

        handleSaveChanges();
    }

    const handleRemoveImage = (value: string, key: string) => {
        const activeEditingField = editingComponent.fields[key].value;

        if (!activeEditingField) return;

        setEditingComponent((prev) => {
            if (Array.isArray(activeEditingField)) {
                const index = prev.fields[key].value.findIndex((item) => item === value);
                prev.fields[key].value.splice(index, 1);
            } else {
                prev.fields[key].value = ''
            }

            return { ...prev };
        });
    };

    const handleSaveLayoutChanges = async () => {
        const activeLayout = initialLayout[editingComponent?.component?.toLocaleLowerCase()];
        
        setSaving(true);

        const hasPendingChanges = await new Promise((resolve) => {
            setTimeout(() => {
                resolve(!isEqual(
                    Object.entries(activeLayout?.fields).map(([_, value]) => (value as any)?.value),
                    Object.entries(editingComponent?.fields).map(([_, value]) => (value as any)?.value),
                ));
            }, 500);
        });

        if (hasPendingChanges) {
            fetchLayoutData()
            setHasStagedChanges(true);
            setSaving(false);
        } else {
            setSaving(false);
        }

    }

    const handleSaveChanges = async () => {
        const activePage = initialData.find((_, index) => index === pageIndex)?.blocks;
        const activeComponentData = activePage?.find((block) => block.id === editingComponent?.id);

        setSaving(true);

        const hasPendingChanges = await new Promise((resolve) => {
            setTimeout(() => {
                resolve(!isEqual(
                    Object.entries(activeComponentData?.fields).map(([_, value]) => (value as any)?.value),
                    Object.entries(editingComponent?.fields).map(([_, value]) => (value as any)?.value),
                ));
            }, 500);
        });

        if (hasPendingChanges) {
            await fetchBaseData(STAGED_CHANGES_KEY);
            setSaving(false);
            setHasStagedChanges(true);
        } else {
            setSaving(false);
        }

        setEditingComponent(null);
    };

    const fetchInitialData = async () => {
        const { data } = await axios.post('https://api.flospace.io/get-site-data', { siteKey });

        setAllSiteData(data);

        const activePage = data?.pageData[pageIndex];
        const activeLayout = data?.layout;
        console.log(activeLayout);
        const updates = activePage.blocks.map((block) => {
            if (block.id === editingComponent?.id) {
                return { ...block, fields: { ...editingComponent?.fields } }
            }
            return { ...block };
        });

        const allData = data?.pageData?.map((block) => {
            if (block.url === activePage.url) {
                return { ...block, blocks: updates }
            }
            return { ...block };
        });

        setInitialData(allData);
        setInitialLayout(activeLayout);
    };

    const fetchNewPage = (index: number) => {
        setPageIndex(index);
        fetchInitialData();
    };

    const fetchLayoutData = async () => {
        const { data } = await axios.post('https://api.flospace.io/get-site-data', { siteKey });

        const activeLayout = data?.layout;
        const previousSaveData = JSON.parse(localStorage.getItem(STAGED_LAYOUT_KEY)) ?? null;

        const layoutToUse = previousSaveData ? Object.entries(previousSaveData) : Object.entries(activeLayout);

        const updates = layoutToUse.map(([key, block]: any) => {
            if (block.id === editingComponent.id) {
                return { [key]: { ...block, fields: { ...editingComponent.fields } } }
            } else {
                return { [key]: { ...block } }
            }
        }).reduce((acc, cur) => {
            acc[Object.keys(cur)[0]] = Object.values(cur)[0];
            return acc;
        });
    
        localStorage.setItem(STAGED_LAYOUT_KEY, JSON.stringify({ ...updates }))
        // if (previousSaveData) {
        //     localStorage.setItem(STAGED_LAYOUT_KEY, JSON.stringify({ ...previousSaveData, ...updates }))
        // } else {
        // }
    
        setHasStagedChanges(true);
        setInitialLayout(activeLayout);
    }

    const fetchBaseData = async (key: string) => {
        const { data } = await axios.post('https://api.flospace.io/get-site-data', { siteKey });

        const activePage = data?.pageData[pageIndex];
        const previousSaveData = JSON.parse(localStorage.getItem(key)) ?? null;

        const blocksToUse = previousSaveData ? Object.values(previousSaveData)[pageIndex] : activePage
        
        const updates = blocksToUse.blocks.map((block) => {
            if (block.id === editingComponent?.id) {
                return { ...block, fields: { ...editingComponent?.fields } }
            }
            return { ...block };
        });

        const allData = data?.pageData?.map((block) => {
            if (block.url === activePage.url) {
                return { ...block, blocks: updates }
            }
            return { ...block };
        });

        if (previousSaveData) {
            localStorage.setItem(key, JSON.stringify({ ...previousSaveData, ...allData }));
        } else {
            localStorage.setItem(key, JSON.stringify({ ...allData }));
        }

        setInitialData(data?.pageData);
    };

    const handlePublishChanges = () => {
        const localSaveBlockData = JSON.parse(localStorage.getItem(STAGED_CHANGES_KEY)) ?? null;
        const localSaveLayoutData = JSON.parse(localStorage.getItem(STAGED_LAYOUT_KEY)) ?? null;

        if (!localSaveBlockData) {
            handlePublishLayoutChanges();
            return;
        };

        setPublishing(true);

        const resolvedData = Object.entries(localSaveBlockData).map(([_, values]) => values);

        axios.post('https://api.flospace.io/save-site-data', {
            siteKey,
            fileName: 'pages',
            fileData: { ...resolvedData }
        })
            .then((data) => {
                if (localSaveLayoutData) {
                    handlePublishLayoutChanges();
                    localStorage.removeItem(STAGED_CHANGES_KEY);
                    return;
                }

                clearPublishedState();
            });
    };

    const handlePublishLayoutChanges = () => {
        const localSaveLayoutData = JSON.parse(localStorage.getItem(STAGED_LAYOUT_KEY)) ?? null;

        if (!localSaveLayoutData) return;

        setPublishing(true);
        
        axios.post('https://api.flospace.io/save-site-data', {
            siteKey,
            fileName: 'layout',
            fileData: { ...localSaveLayoutData }
        })
            .then((data) => {
                localStorage.removeItem(STAGED_LAYOUT_KEY);
                clearPublishedState();
            });
    };

    const clearPublishedState = () => {
        setHasStagedChanges(false);
        setPublishing(false);
        setPublishedChanges(true);
        setTimeout(() => setPublishedChanges(false), 4000);
    };

    const debugLocal = () => {
        // const previousSaveData = JSON.parse(localStorage.getItem(STAGED_CHANGES_KEY)) ?? null;
        // const resolvedData = Object.entries(previousSaveData).map(([_, values]) => values);
    };

    return (
        <EditContext.Provider
            value={{
                isEditing,
                editingComponent,
                saving,
                stagedChanges,
                hasStagedChanges,
                publishing,
                publishedChanges,
                sidebarOpen,
                siteKey,
                allSiteData,
                pageIndex,
                fetchNewPage,
                setSidebarOpen,
                handlePublishChanges,
                setEditingComponent,
                handleEditTextValue,
                handleEditDynamicLink,
                handleEditSecondaryLink,
                handleAddImage,
                handleRemoveImage,
                handleSaveChanges,
                handleSaveLayoutChanges,
                handleEditTextFromArray,
                handleEditImageFromArray,
                setSiteKey,
                setPageIndex,
                setIsEditing,
                debugLocal
            }}
        >
            {children}
        </EditContext.Provider>
    );
};

export const useEditContext = () => {
    const context = useContext(EditContext);
    return context;
}
