export const MOBILE_BREAKPOINT = 480;
export const TABLET_BREAKPOINT = 768;
export const LAPTOP_BREAKPOINT = 1680;
export const REDUCED_WIDTH_BREAKPOINT = 1350;

export const CONTENT_MAX_WIDTH = 1400;
export const SIDEBAR_WIDTH = 280;

export const PRIMARY_RGB = '0, 179, 255';
export const SECONDARY_RGB = '55, 115, 245';
export const GRAY_RGB = '91, 97, 110';

// Mainly to break this out for charts
export const THEME_GREEN_RGB = '45, 214, 65'; // #2dd641
export const THEME_RED_RGB = '235, 64, 52'; // #eb4034