import styled, { keyframes } from 'styled-components';

import { AccountMenu } from '../../components/Navbar';
import { buildBreakpoint } from '../../styles/common';
import { useEditContext } from '../../context/EditContext';
import { Icon } from '../../components/Icon';

const NavbarContainer = styled.header<{ $sidebarOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 1px;
    z-index: 100;
    height: 50px;
    backdrop-filter: blur(6px);
    padding: 16px;
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    transition 0.2s;
    background-color: ${({ theme }) => theme.bodyFaded};
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    margin-left: ${(props) => props.$sidebarOpen ? 20 : 0}rem;

    ${buildBreakpoint('tablet')} {
        margin-left: 0;
        border-left: none;
    };
`;

const NavbarInnerContainer = styled.div`
    padding: 0 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const NavActionsWrapper = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr;
    gap: 2rem;
`;

const MenuButtonWrapper = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    
    svg {
        transition: 0.2s;
    }
`;

const rotateAnim = keyframes`
    from { transform: rotate(0deg) }
    to { transform: rotate(-360deg) }
`;

const SavedChangesWrapper = styled.div<{ $saving?: boolean; }>`
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    opacity: ${(props) => props.$saving ? 1 : 0.6};

    strong {
        min-width: 120px;
        text-align: center;
        margin-right: 1rem;
    }

    svg {
        animation: ${(props) => props.$saving ? rotateAnim : null} 2s linear infinite;
    }
`;

const PublishButton = styled.button`
    font-family: inherit;
    padding: 0.5rem 2rem;
    border-radius: 10px;
    border: 2px solid transparent;
    font-weight: bold;
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 0.8rem;
    cursor: pointer;
    transition: 0.2s;
    min-width: 180px;

    &:disabled {
        opacity: 0.6;
        border: 2px solid ${({ theme }) => theme.colors.primary};
        background: transparent;
        cursor: default;
    }

    :not([disabled]) {
        color: white;
    }

    &:hover:not([disabled]) {
        background: transparent;
        border: 2px solid ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.textPrimary};
    }
`;

export const EditNavbar: React.FC = () => {
    const { saving, publishing, hasStagedChanges, handlePublishChanges, sidebarOpen, setSidebarOpen } = useEditContext();

    const handleOpenSidebar = () => setSidebarOpen(!sidebarOpen)

    return (
        <NavbarContainer $sidebarOpen={false}>
            <NavbarInnerContainer>
                <NavActionsWrapper>
                    <MenuButtonWrapper onClick={handleOpenSidebar}>
                        <Icon variant={sidebarOpen ? "menuOpen" : "menu"} />
                    </MenuButtonWrapper>
                    <SavedChangesWrapper $saving={saving}>
                        <strong>{saving ? 'Saving...' : 'All changes saved'}</strong>
                        <Icon fill={saving ? 'chartreuse' : null} variant='saved' width={12} height={12} />
                    </SavedChangesWrapper>
                    <PublishButton
                        disabled={!hasStagedChanges || publishing || saving}
                        onClick={handlePublishChanges}
                    >
                        {publishing ? 'Publishing...' : 'Publish Changes'}
                    </PublishButton>
                </NavActionsWrapper>
                <AccountMenu />
            </NavbarInnerContainer>
        </NavbarContainer>
    );
};
