import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthContext } from '../context/AuthContext';
import { Loader } from '../pages/Loading';

export const NonAuthRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { isLoggedIn, initialized } = useAuthContext();

    if (!initialized) {
        return <Loader />;
    }

    if (isLoggedIn) {
        return <Navigate to="/dashboard" />;
    }

    return <>{children}</>;
}