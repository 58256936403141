import React from 'react';
import { ProviderProps } from '../@types/types';

import { AuthContextProvider } from './AuthContext';
import { NavContextProvider } from './NavContext';
import { ThemeContextProvider } from './ThemeContext';
import { EditContextProvider } from './EditContext';
import { ModalContextProvider } from './ModalContext';

export const GlobalContext: React.FC<ProviderProps> = ({ children }) => {
    return (
        <AuthContextProvider>
            <ThemeContextProvider>
                <NavContextProvider>
                    <EditContextProvider>
                        <ModalContextProvider>
                            {children}
                        </ModalContextProvider>
                    </EditContextProvider>
                </NavContextProvider>
            </ThemeContextProvider>
        </AuthContextProvider>
    );
};
