import { useState, useEffect, createContext, useContext } from 'react';
import { ProviderProps } from '../@types/types';

interface IModalContext {
    open: boolean;
    image: string;
    images?: string[];
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    openModal: (image: string) => void;
    handleClose: () => void;
    setImages?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ModalContext = createContext(undefined as unknown as IModalContext);

export const ModalContextProvider: React.FC<ProviderProps> = ({ children }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [images, setImages] = useState<string[]>([]);
    const [image, setImage] = useState<string | null>(null);

    const openModal = (image: string) => {
        setImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setImage(null);  
    };

    useEffect(() => {
        document.body.style.overflow = open ? 'hidden' : 'auto';
    }, [open]);

    return (
        <ModalContext.Provider value={{ open, image, images, openModal, handleClose, setOpen, setImages }}>
            {children}
        </ModalContext.Provider>
    );
};

export const useModalContext = () => {
    return useContext(ModalContext);
}
