import { createContext, useContext, useState, useEffect } from 'react';

import { ProviderProps } from '../@types/types';

export enum ThemeMode {
    DARK = 'dark',
    LIGHT = 'light'
}

interface ThemeContextInterface {
    theme: ThemeMode;
    toggleTheme: () => void;
}

const STORAGE_KEY = 'theme_mode';

export const ThemeContext = createContext(undefined as unknown as ThemeContextInterface);

export const ThemeContextProvider: React.FC<ProviderProps> = ({ children }) => {
    const [theme, setTheme] = useState<ThemeMode>(ThemeMode.LIGHT);

    useEffect(() => {
        const savedTheme = window.localStorage.getItem(STORAGE_KEY) as ThemeMode;
        const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

        if (savedTheme) {
            setTheme(savedTheme);
        } else if (prefersDark) {
            setTheme(ThemeMode.DARK);
        }

    }, []);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? ThemeMode.DARK : ThemeMode.LIGHT;
        setTheme(newTheme);
        window.localStorage.setItem(STORAGE_KEY, newTheme);
    }
    return (
        <ThemeContext.Provider
            value={{ theme, toggleTheme }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => {
    const context = useContext(ThemeContext);
    return context;
};
