import { createContext, useEffect, useState, useMemo, useContext } from 'react';

import { ProviderProps, User } from '../@types/types';
import {
    auth,
    signOut,
    db,
    onAuthStateChanged,
    UserInterface,
} from '../firebase';

interface AuthContextProps {
    user: User;
    initialized: boolean;
    isLoggedIn: boolean;
    logOut: () => void;
}

export const AuthContext = createContext(undefined as unknown as AuthContextProps);

export const AuthContextProvider: React.FC<ProviderProps> = ({ children }) => {
    const [userData, setUserData] = useState<User | null>(null);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setUserData(user);

            if (user) {
                db.get<UserInterface>(`users/${user.uid}`)
                .then((data) => {
                    setUserData((prev) => ({
                        ...prev,
                        ...data
                    }));
                });
            }

            setIsLoggedIn(!!user);
            setInitialized(true);
        })
    }, []);

    const user = useMemo(() => {
        return {
            id: userData?.uid,
            ...userData
        }
    }, [userData]);

    const logOut = () => signOut(auth);

    return (
        <AuthContext.Provider
            value={{
                user,
                isLoggedIn,
                initialized,
                logOut
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = (): AuthContextProps => {
    const context = useContext(AuthContext);
    return context;
}
