import './index.css';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { GlobalContext } from './context/GlobalContext';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <GlobalContext>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </GlobalContext>
);
