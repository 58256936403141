import React, { ReactElement } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Icon } from '../../components/Icon';

import { buildBreakpoint } from '../../styles/common';

const SidebarContainer = styled.nav`
    width: 274px;
    position: fixed;
    top: 0;
    min-height: 100vh;
    border-right: 1px solid ${(props) => props.theme.colors.border};

    ${buildBreakpoint('tablet')} {
        display: none;
    };
`;

const SidebarInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
`;

const TitleContainer = styled.div`
    h2 {
        color: ${({ theme }) => theme.colors.text};
        margin-top: 0.45rem;
    }
`;

const LinksContainer = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const LinkItemContainer = styled(Link)`
    cursor: pointer;
    font-size: 0.875rem;
    text-decoration: none;
    color: inherit;
    transition: 0.25s;
    margin-bottom: 1rem;
    width: 100%;
    vertical-align: middle;
`;

const activeStyles = css`
    border: none;
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.navItemActive};
`;

const LinkItemWrapper = styled.div<{ $active?: boolean }>`
    text-align: center;
    padding: 0.375rem 1rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    transition: 0.2s;

    span {
        font-weight: 600;
        color: ${(props) => props.$active ? props.theme.colors.text : props.theme.colors.navItem};
    }

    svg {
        width: 20px;
        fill: ${(props) => props.$active ? props.theme.colors.primary : props.theme.colors.navItem};
    }

    :hover {
        background-color: ${({ theme }) => theme.colors.navItemActive};
    }
    ${(props) => props.$active && activeStyles};
`;

const LinkIconWrapper = styled.div<{ $active?: boolean }>`
    margin-right: 1rem;
    svg {
        transition: 0.25s;
        fill: ${(props) => props.$active && props.theme.colors.primary};
    }
`;

interface SidebarLinkProps {
    to: string;
    label: string;
    icon?: React.ReactElement;
};

const links: SidebarLinkProps[] = [
    {
        to: '/dashboard',
        label: 'Websites',
        icon: <Icon variant='site' />
    },
    {
        to: '/account',
        label: 'Account',
        icon: <Icon variant='account' />
    },
    {
        to: '/invoices',
        label: 'Invoices',
        icon: <Icon variant='receipt' />
    },
    {
        to: '/tickets',
        label: 'Tickets',
        icon: <Icon variant='inbox' />
    },
];

const SidebarLinkItem = ({ to, label, icon }: SidebarLinkProps): ReactElement => {
    const path = window.location.pathname;

    return (
        <LinkItemContainer to={to}>
            <LinkItemWrapper $active={path === to}>
                <LinkIconWrapper>
                    {icon}
                </LinkIconWrapper>
                <span>{label}</span>
            </LinkItemWrapper>
        </LinkItemContainer>
    );
};

export const Sidebar: React.FC = () => {
    return (
        <SidebarContainer>
            <SidebarInnerContainer>
                <TitleContainer>
                    <h2>FloPortal</h2>
                </TitleContainer>
                <LinksContainer>
                    {links.map((item) => (
                        <SidebarLinkItem key={item.to} {...item} />
                    ))}
                </LinksContainer>
            </SidebarInnerContainer>
        </SidebarContainer>
    );
};
