import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { DashboardLayout } from '../layout/DashboardLayout';
import { AuthRoute } from './AuthRoute';
import { NonAuthRoute } from './NonAuthRoute';
import { EditLayout } from '../layout/EditLayout';

const Loadable = (Component: any) => (props: any) => (
    <Suspense fallback={<div />}>
        <Component {...props} />
    </Suspense>
);

// Logged in imports
const MySitePage = Loadable(lazy(() => import('../pages/MySite')));
const AccountPage = Loadable(lazy(() => import('../pages/Account')));
const InvoicesPage = Loadable(lazy(() => import('../pages/Invoices')));
const TicketsPage = Loadable(lazy(() => import('../pages/Tickets')));
const SiteBuilderPage = Loadable(lazy(() => import('../pages/SiteBuilder')));

// Logged out imports
const LoginPage = Loadable(lazy(() => import('../pages/Login')));
const SignUpPage = Loadable(lazy(() => import('../pages/SignUp')));

// Dev routes
const AllIconsPage = Loadable(lazy(() => import('../pages/Dev/AllIcons')));

interface RoutesInterface {
    path: string;
    element: React.ReactNode;
};

const loggedInRoutes: RoutesInterface[] = [
    {
        path: '/dashboard',
        element: (
            <AuthRoute>
                <DashboardLayout>
                    <MySitePage />
                </DashboardLayout>
            </AuthRoute>
        )
    },
    {
        path: '/site/:siteId',
        element: (
            <AuthRoute>
                <EditLayout>
                    <SiteBuilderPage />
                </EditLayout>
            </AuthRoute>
        )
    },
    {
        path: '/account',
        element: (
            <AuthRoute>                
                <DashboardLayout>
                    <AccountPage />
                </DashboardLayout>
            </AuthRoute>
        )
    },
    {
        path: '/invoices',
        element: (
            <AuthRoute>
                <DashboardLayout>
                    <InvoicesPage />
                </DashboardLayout>
            </AuthRoute>
        )
    },
    {
        path: '/tickets',
        element: (
            <AuthRoute>
                <DashboardLayout>
                    <TicketsPage />
                </DashboardLayout>
            </AuthRoute>
        )
    },
];

const loggedOutRoutes: RoutesInterface[] = [
    {
        path: '/login',
        element: (
            <NonAuthRoute>
                <LoginPage />
            </NonAuthRoute>
        )
    },
    {
        path: '/sign-up',
        element: (
            <NonAuthRoute>
                <SignUpPage />
            </NonAuthRoute>
        )
    }
];

const devRoutes: RoutesInterface[] = [
    {
        path: '/dev/icons',
        element: (
            <AllIconsPage />
        )
    }
]

export const routes: RoutesInterface[] = [
    ...loggedInRoutes,
    ...loggedOutRoutes,
    ...devRoutes,
    {
        path: '*',
        element: <Navigate to="/dashboard" />
    },
]