import { createContext, useContext, useState } from 'react';

import { ProviderProps } from '../@types/types';

interface NavContextInterface {
    title: string;
    setTitle: (title: string) => void;
}

export const NavContext = createContext(undefined as unknown as NavContextInterface);

export const NavContextProvider: React.FC<ProviderProps> = ({ children }) => {
    const [title, setTitle] = useState<string>('Home');

    return (
        <NavContext.Provider
            value={{
                title,
                setTitle
            }}
        >
            {children}
        </NavContext.Provider>
    );
};

export const useNavContext = () => {
    const context = useContext(NavContext);
    return context;
};
